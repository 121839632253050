<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 10C13 8.3 11.7 7 10 7C8.30003 7 7.00003 8.3 7.00003 10C7.00003 11.7 8.30003 13 10 13C11.7 13 13 11.7 13 10ZM15 1.3C11.1 -0.9 6.30003 -0.2 3.10003 2.7V1C3.10003 0.4 2.70003 0 2.10003 0C1.50003 0 1.10003 0.4 1.10003 1V5.5C1.10003 6.1 1.50003 6.5 2.10003 6.5H6.60003C7.20003 6.5 7.60003 6.1 7.60003 5.5C7.60003 4.9 7.20003 4.5 6.60003 4.5H4.20003C5.70003 2.9 7.80003 2 10 2C14.4 2 18 5.6 18 10C18 10.6 18.4 11 19 11C19.6 11 20 10.6 20 10C20 6.4 18.1 3.1 15 1.3ZM17.9 13.5H13.4C12.8 13.5 12.4 13.9 12.4 14.5C12.4 15.1 12.8 15.5 13.4 15.5H15.8C14.3 17.1 12.2 18 10 18C5.60003 18 2.00003 14.4 2.00003 10C2.00003 9.4 1.60003 9 1.00003 9C0.40003 9 3.05176e-05 9.4 3.05176e-05 10C3.05176e-05 15.5 4.50003 20 10 20C12.6 20 15 19 16.9 17.2V19C16.9 19.6 17.3 20 17.9 20C18.5 20 18.9 19.6 18.9 19V14.5C18.9 13.9 18.4 13.5 17.9 13.5Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>